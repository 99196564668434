import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Shadows,
  accordionSummaryClasses,
  alpha,
  createTheme,
  darken,
  inputLabelClasses,
  lighten,
  outlinedInputClasses,
  typographyClasses,
} from '@mui/material';

import { Theme } from './types';

const SPACING_FACTOR = 8;

const customShadows = Array(25).fill('none') as Shadows;
customShadows[1] = '0 2px 4px 0 rgba(0, 0, 0, 0.05)'; // light
customShadows[2] = '0 2px 4px 0 rgba(0, 0, 0, 0.1)'; // default
customShadows[3] = '0 2px 6px 0 rgba(0, 0, 0, 0.12)'; // hover
customShadows[4] = '0 2px 4px 0 rgba(0, 0, 0, 0.25)'; // dark

export const createMuiTheme = (orgTheme: Theme) =>
  createTheme({
    spacing: SPACING_FACTOR,
    typography: {
      fontFamily: orgTheme.fontFamily,
      fontSize: orgTheme.baseFontSize,
      fontWeightRegular: 400,
      fontWeightBold: 600,
      h1: {
        fontSize: '1.5rem',
        margin: '0 0 1rem 0',
        fontWeight: 600,
      },
      h2: {
        fontSize: '1.25rem',
        margin: '0 0 1rem 0',
        fontWeight: 600,
      },
      h3: {
        fontSize: '1rem',
        margin: 600,
      },
    },
    palette: {
      primary: {
        main: orgTheme.primary,
        light: lighten(orgTheme.primary, 0.1),
        dark: darken(orgTheme.primary, 0.1),
        contrastText: orgTheme.contentContrast,
      },
      error: {
        main: orgTheme.negative,
        light: lighten(orgTheme.negative, 0.1),
        dark: darken(orgTheme.negative, 0.1),
        contrastText: orgTheme.contentContrast,
      },
      success: {
        main: orgTheme.positive,
        light: lighten(orgTheme.positive, 0.1),
        dark: darken(orgTheme.positive, 0.1),
        contrastText: orgTheme.contentContrast,
      },
      text: {
        primary: orgTheme.contentPrimary,
        secondary: orgTheme.contentSecondary,
        disabled: orgTheme.contentTertiary,
      },
    },
    shadows: customShadows,
    components: {
      MuiAccordion: {
        defaultProps: {
          elevation: 0,
        },
        styleOverrides: {
          root: {
            margin: 0,
            '&:before': {
              display: 'none',
            },
            '&.Mui-expanded': {
              margin: '0 0',
            },
          },
        },
      },
      MuiAccordionSummary: {
        defaultProps: {
          expandIcon: <ExpandMoreIcon sx={{ width: 25, height: 25 }} />,
        },
        styleOverrides: {
          root: ({ theme }) => ({
            padding: `0 ${theme.spacing(2)}`,
            borderRadius: `${theme.spacing(1)}`,
            [`&, .${typographyClasses.root}, .${accordionSummaryClasses.expandIconWrapper}`]:
              {
                transition: theme.transitions.create([
                  'background-color',
                  'color',
                ]),
              },
            [`&, &.${accordionSummaryClasses.expanded}`]: {
              minHeight: 45,
            },
            '&:hover, &:focus, &:focus-visible, &:active': {
              [`&, .${typographyClasses.root}, .${accordionSummaryClasses.expandIconWrapper}`]:
                {
                  color: theme.palette.primary.main,
                },
              backgroundColor: alpha(theme.palette.primary.main, 0.05),
            },
          }),
          content: {
            '&.Mui-expanded': {
              margin: '12px 0',
            },
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: ({ theme }) => ({
            padding: theme.spacing(2),
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(2),
          }),
        },
      },
      MuiCardHeader: {
        styleOverrides: {
          root: {
            padding: 0,
          },
          title: {
            fontWeight: 600,
          },
        },
      },
      MuiCardContent: {
        styleOverrides: {
          root: {
            padding: 0,
          },
        },
      },
      MuiCardActions: {
        styleOverrides: {
          root: {
            padding: 0,
            justifyContent: 'flex-end',
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            padding: 0,
          },
          input: ({ theme }) => ({
            [`&.${outlinedInputClasses.input}`]: {
              padding: `${theme.spacing(1.25)} ${theme.spacing(
                1.25
              )} ${theme.spacing(1.25)} 0`,
            },
          }),
        },
      },
      MuiInputLabel: {
        defaultProps: {
          shrink: true,
        },
        styleOverrides: {
          root: ({ theme }) => ({
            position: 'relative',
            transform: 'none',
            fontSize: '1rem',
            fontWeight: 600,
            marginBottom: theme.spacing(0.5),
            [`&, &:hover, &.${inputLabelClasses.focused}, &.${inputLabelClasses.error}`]:
              {
                color: theme.palette.text.primary,
              },
          }),
        },
      },
      MuiOutlinedInput: {
        defaultProps: {
          notched: false,
        },
        styleOverrides: {
          root: ({ theme }) => ({
            paddingLeft: theme.spacing(1),
            // Hover State
            [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: orgTheme.elementBorder,
              borderWidth: 1,
            },
            // Focus State
            [`&.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]:
              {
                borderColor: orgTheme.primary,
                borderWidth: 1,
                boxShadow: `0 0 0 2px ${alpha(orgTheme.primary, 0.25)}`,
              },
            // Error State
            [`&.${outlinedInputClasses.error}`]: {
              [`& .${outlinedInputClasses.notchedOutline}, &:hover .${outlinedInputClasses.notchedOutline}`]:
                {
                  borderColor: theme.palette.error.main,
                  boxShadow: `0 0 0 2px ${alpha(
                    theme.palette.error.main,
                    0.25
                  )}`,
                },
            },
          }),
          notchedOutline: ({ theme }) => ({
            borderColor: orgTheme.elementBorder,
            transition: theme.transitions.create(['border-color', 'boxShadow']),
          }),
        },
      },
      MuiPaper: {
        defaultProps: {
          elevation: 0,
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: ({ theme }) => ({
            margin: `${theme.spacing(0.5)} 0`,
            fontWeight: 600,
          }),
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            borderColor: orgTheme.faintBorder,
          },
        },
      },
    },
  });
