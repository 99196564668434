import { ThemeProvider as MuiThemeProvider } from '@mui/material';
import omitBy from 'lodash/omitBy';
import {
  ReactNode,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';

import UserRole from 'enums/UserRole';

import {
  OrganizationThemeFragment,
  useGetOrganizationThemeForOrganizationAdminLazyQuery,
  useGetOrganizationThemeForStudentLazyQuery,
  useGetOrganizationThemeForTeacherLazyQuery,
} from 'graphql/types/generated';

import { getUserId, getUserRole } from 'redux/user';

import {
  DEFAULT_COLOR_PALETTE,
  SYLLABIRD_THEME,
  defaultValue,
} from './constants';
import { createMuiTheme } from './createMuiTheme';
import setColors from './setColors';
import setFonts from './setFonts';
import {
  ColorPalette,
  ConfigurableTheme,
  Theme,
  ThemeContextType,
} from './types';

export const ThemeContext = createContext<ThemeContextType>(defaultValue);

interface ThemeProviderProps {
  children: ReactNode;
}

export const ThemeProvider = ({ children }: ThemeProviderProps) => {
  const [theme, setTheme] = useState<Theme>(SYLLABIRD_THEME);
  const [isPreviewingTheme, setIsPreviewingTheme] = useState<boolean>(false);
  const [isThemeLoading, setIsThemeLoading] = useState(true);
  const [themeId, setThemeId] = useState<string | null>(null);

  const muiTheme = useMemo(() => createMuiTheme(theme), [theme]);

  const userId = useSelector(getUserId);
  const userRole = useSelector(getUserRole);

  const setDefaultTheme = () => {
    setTheme(SYLLABIRD_THEME);
    setColors(SYLLABIRD_THEME);
    setFonts(SYLLABIRD_THEME);
  };

  const processTheme = useCallback(
    (organizationTheme?: OrganizationThemeFragment | null) => {
      if (!organizationTheme) {
        // Skip processing null/undefined organization themes to
        // default to the Syllabird theme.
        setDefaultTheme();
        return;
      }

      const organizationOverrides = omitBy(
        organizationTheme,
        (value) => !value
      );
      const organizationColorPaletteOverrides = omitBy(
        organizationTheme?.colorPalette,
        (value) => !value
      );

      const mergedColorPalette: ColorPalette = {
        ...DEFAULT_COLOR_PALETTE,
        ...organizationColorPaletteOverrides,
      };

      const mergedTheme: Theme = {
        ...SYLLABIRD_THEME,
        ...organizationOverrides,
        colorPalette: mergedColorPalette,
      };

      // Set context value for use in JS files
      setTheme(mergedTheme);

      // Set CSS variables for use in SCSS files
      setColors(mergedTheme);

      // Add necessary font script tags and set font family CSS variable
      setFonts(mergedTheme);

      setThemeId(organizationTheme.id);
    },
    []
  );

  const [getOrganizationThemeForTeacher] =
    useGetOrganizationThemeForTeacherLazyQuery({
      onError: () => {
        setDefaultTheme();
        setIsThemeLoading(false);
      },
    });

  const [getOrganizationThemeForStudent] =
    useGetOrganizationThemeForStudentLazyQuery({
      onError: () => {
        setDefaultTheme();
        setIsThemeLoading(false);
      },
    });

  const [getOrganizationThemeForOrganizationAdmin] =
    useGetOrganizationThemeForOrganizationAdminLazyQuery({
      onError: () => {
        setDefaultTheme();
        setIsThemeLoading(false);
      },
    });

  const getThemeData = useCallback(
    async (userId: string, userRole: UserRole) => {
      if (userRole === UserRole.Teacher) {
        const { data } = await getOrganizationThemeForTeacher({
          variables: { teacherId: userId },
        });
        processTheme(data?.teacher?.organization?.theme);
      } else if (userRole === UserRole.Student) {
        const { data } = await getOrganizationThemeForStudent({
          variables: { studentAccountId: userId },
        });
        processTheme(
          data?.studentAccount?.student?.teachers?.[0]?.teacher?.organization
            ?.theme
        );
      } else if (userRole === UserRole.OrganizationAdmin) {
        const { data } = await getOrganizationThemeForOrganizationAdmin({
          variables: { organizationAdminId: userId },
        });
        processTheme(data?.organizationAdmin?.organization?.theme);
      } else {
        processTheme(null);
      }
    },
    [
      getOrganizationThemeForTeacher,
      getOrganizationThemeForStudent,
      getOrganizationThemeForOrganizationAdmin,
      processTheme,
    ]
  );

  useEffect(() => {
    (async () => {
      if (userId && userRole) {
        await getThemeData(userId, userRole);
        setIsThemeLoading(false);
      }
    })();
  }, [userId, userRole, getThemeData]);

  const previewTheme = useCallback(
    (previewTheme: Partial<ConfigurableTheme>) => {
      const mergedTheme = {
        ...theme,
        ...previewTheme,
        colorPalette: {
          ...theme.colorPalette,
          ...previewTheme.colorPalette,
        },
      };

      setTheme(mergedTheme);
      setColors(mergedTheme);
      setFonts(mergedTheme);
      setIsPreviewingTheme(true);
    },
    [theme]
  );

  const refreshTheme = useCallback(async () => {
    if (userId && userRole) {
      await getThemeData(userId, userRole);
    }
  }, [userId, userRole, getThemeData]);

  return (
    <ThemeContext.Provider
      value={{
        loading: isThemeLoading,
        theme,
        themeId,
        isPreviewingTheme,
        setIsPreviewingTheme,
        previewTheme,
        refreshTheme,
      }}
    >
      <MuiThemeProvider theme={muiTheme}>{children}</MuiThemeProvider>
    </ThemeContext.Provider>
  );
};
