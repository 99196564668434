import { useAuth0 } from '@auth0/auth0-react';
import AwsS3 from '@uppy/aws-s3';
import Uppy from '@uppy/core';
import axios from 'axios';
import merge from 'lodash/merge';
import { useEffect, useMemo } from 'react';

import { getApiRoot, getHeaders } from 'api/apiConfig';

export interface UppyOptions {
  autoProceed: boolean;
  restrictions: Partial<{
    maxNumberOfFiles: number;
    maxFileSize: number;
    allowedFileTypes: string[];
  }>;
}

export const IconFileExtensions = ['.svg'];
export const ImageFileExtensions = ['.png', '.jpg', '.jpeg', '.gif'];
export const DocumentFileExtensions = [
  '.pdf',
  '.doc',
  '.docx',
  '.ppt',
  '.pptx',
  '.xls',
  '.xlsx',
  '.csv',
  '.txt',
];

export const defaultOptions: UppyOptions = {
  autoProceed: true,
  restrictions: {
    maxNumberOfFiles: 1,
    // 100MB
    maxFileSize: 104_857_600,
    allowedFileTypes: [],
  },
};

export default function useUppy(
  id: string,
  isAttachment: boolean = false,
  options: Partial<UppyOptions> = defaultOptions
) {
  const { getAccessTokenSilently } = useAuth0();

  const uppy = useMemo(() => {
    const mergedOptions = merge(defaultOptions, options);

    return new Uppy({
      id,
      ...mergedOptions,
    }).use(AwsS3, {
      id: `AwsS3-${id}`,
      // 60secs
      timeout: 60 * 1000,
      getUploadParameters: async (file) => {
        const token = await getAccessTokenSilently();
        const presignedHeaders = getHeaders(token);

        const { data } = await axios.post(
          `${getApiRoot()}/uppy/s3/presigned`,
          {
            fileName: file.name,
            contentType: file.type ?? 'application/octet-stream',
            isAttachment,
          },
          presignedHeaders
        );

        let uppyHeaders: Record<string, string> = {
          'content-type': file.type ?? 'application/octet-stream',
        };

        if (isAttachment) {
          uppyHeaders = {
            ...uppyHeaders,
            'content-disposition': 'attachment',
          };
        }

        return {
          method: 'PUT',
          url: data.url as string,
          // fields: {},
          headers: uppyHeaders,
        };
      },
    });
  }, [id, options, isAttachment, getAccessTokenSilently]);

  useEffect(() => {
    return () => uppy.close();
  }, [uppy]);

  return uppy;
}
