import { format } from 'date-fns';
import { Fragment } from 'react';

import Logo from 'components/Logo';
import Note from 'components/Note';

import styles from './MaintenancePage.module.scss';

interface MaintenancePageProps {
  message?: string;
  startTime?: string;
  endTime?: string;
}

function MaintenancePage({
  message,
  startTime,
  endTime,
}: MaintenancePageProps): JSX.Element {
  return (
    <div className={styles.MaintenancePage}>
      <div className={styles.Content}>
        <Logo className={styles.Logo} />
        <h1 className={styles.Title}>We'll be back soon!</h1>
        <div className={styles.DefaultMessage}>
          We are very sorry for the inconvenience but we are performing
          maintenance at the moment. We will be back online shortly!
          <span role="img" aria-label="Tools emoji">
            🛠️
          </span>
        </div>
        <div className={styles.SupportMessage}>
          Need help? Contact us at{' '}
          <a href="mailto:support@syllabird.com">support@syllabird.com</a>
        </div>
        {(message || startTime || endTime) && (
          <Note className={styles.Note}>
            <Fragment>
              {message && <div>Maintenance Reason: {message}</div>}
              {startTime && (
                <div>Started: {format(new Date(startTime), 'PPpp')}</div>
              )}
              {endTime && (
                <div>
                  Expected completion: {format(new Date(endTime), 'PPpp')}
                </div>
              )}
            </Fragment>
          </Note>
        )}
      </div>
    </div>
  );
}

export default MaintenancePage;
